export const THEME_MODE = Object.freeze({
  LIGHT: "light",
  DARK: "dark",
})

export const IFRAME_EVENT_TYPE = Object.freeze({
  MINIMIZE_CHATBOT: "minimizeChatbot",
  MAXIMIZE_CHATBOT: "maximizeChatbot",
  ROUTE_PATH_CHANGED: "routePathChanged",
  MOVE_TO_LEFT: "moveToLeft",
  OPEN_TENANT_TRAINING_HISTORY: "openTenantTrainingHistory",
  TRAIN_AND_TEST_CLICKED: "trainAndTestClicked",
  GET_USER_LOGIN_ATTRIBUTES: "getUserLoginAttributes",
  GET_USER_LOGIN_ATTRIBUTES_SUCCESS: "getUserLoginAttributesSuccess",
  GET_USER_LOGIN_ATTRIBUTES_ERROR: "getUserLoginAttributesError",
  OPEN_CHATBOT: "openChatbot",
  CLOSE_CHATBOT: "closeChatbot",
  MODIFY_IFRAME_STYLES: "modifyIframeStyles",
})

export const MESSAGE_SENDER = Object.freeze({
  USER: "user",
  CHATBOT: "bot",
  AGENT: "admin",
  SYSTEM: "system",
})

export const MESSAGE_TYPES = Object.freeze({
  TEXT: "text",
  UPLOADED_DOCUMENT: "uploadedDocument",
})

export const METHOD_TYPES = Object.freeze({
  GET: "get",
  POST: "post",
})

export const LOCAL_STORAGE = Object.freeze({
  THEME_VERSION: "ThemeVersion",
  BOT_MESSAGES: "Messages",
  PSID: "Psid",
  LOGIN_ATTRIBUTES: "LoginAttributes",
  CURRENT_SESSION_ID: "CurrentSessionId",
})

export const DEFAULT_THEME_VARIABLES = Object.freeze({
  //------ THEME SPECIFIC -------
  PRIMARY_COLOR: "#f3e2f9",
  SECONDARY_COLOR: "#696a6c",
  FONT_SIZE: "14px",
  FONT_COLOR: "#1f1f1f",
  BUBBLE_SHAPE: "cornered",
  HEADER_HEIGHT: "64px",
  TRIGGER_URL: "",
  DIRECTION: "ltr",

  //------ GLOBAL -------

  TRIGGER_SIZE: "70px",
  TRIGGER_SHAPE: "circle",
  TRIGGER_BOT_OFFSET: "30px",
  TRIGGER_BOT_NOTIFICATION_OFFSET: "20px",
  NOTIFICATION_BODY_WIDTH: "250px",
})

export const BUBBLE_SHAPE = Object.freeze({
  CORNERED: "cornered",
  ROUNDED: "rounded",
})

export const SOCKET_EVENTS = Object.freeze({
  CONNECT: "connect",
  CONNECT_ERROR: "connect_error",
  ERROR: "error",
  DISCONNECT: "disconnect",

  UPDATE: "update",
  NEW_MESSAGE: "newMessage",
  RESPONSE: "response",
  MESSAGE_SEEN: "messageSeen",
  TYPING_STATUS: "typingStatus",
  UNSENT_MESSAGE: "unsentMessage",

  //test events
  NEW_MESSAGE_STREAM: "newMessageStream",
  RESPONSE_STREAM: "responseStream"
})

export const MESSAGE_READ_STATUS = Object.freeze({
  SENT: "sent",
  DELIVERED: "delivered",
  SEEN: "seen",
})

export const RELAY_DATA_TYPES = Object.freeze({
  E_COM_SELECT: "ecommSelect",
})

export const RELAY_DATA_SUBTYPES = Object.freeze({
  DATA: "data"
})

export const COLORS = Object.freeze({
  PRIMARY_MAIN: "#000000",
  PRIMARY_DARK: "#0076D6",
  PRIMARY_LIGHT: "#0076D6",
  SECONDARY_DARK: "#3BA66B",
  SECONDARY_LIGHT: "#6B3BA6",
  TERTIARY_DARK: "#76D600",
  TERTIARY_LIGHT: "#D60076",
})

export const BOT_POSITION = Object.freeze({
  RIGHT: "right",
  LEFT: "left",
})

export const ROUTE_PATHS = Object.freeze({
  HOME: '/',
  CHAT_HISTORY: '/chat-history',
  CHAT: '/chat',
  CHAT_WITH_SESSIONID: '/chat/:sessionId',
  RESOURCES: '/resources',
  KNOWLEDGE_SOURCE: '/knowledge-source',
})

export const DEFAULT_LANGUAGE_MAP = {
  feedbackTemplate: {
    title: "Help us improve",
    textPlaceholder: "Tell us about your experience",
    skipButtonText: "Skip",
    submitButtonText: "Submit",
  },
  botUiTemplate: {
    connectionStatusText: {
      online: [
        "online"
      ],
      offline: [
        "offline"
      ]
    },
    queryPlaceholder: [
      "Enter your Query..."
    ],
    conversationStartButton: [
      "Start a conversation"
    ],
    conversationContinueButton: [
      "Continue conversation"
    ],
    tooltips: {
      home: [
        "Home"
      ],
      myChat: [
        "My Chats"
      ],
      resources: [
        "Resources"
      ]
    },
    pages: {
      chatHistory: {
        title: [
          "Chat History"
        ],
        noChats: [
          "No chats found"
        ]
      },
      resources: {
        title: [
          "Resources"
        ],
        commingSoon: [
          "Comming Soon"
        ]
      }
    }
  }
}