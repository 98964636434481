import dayjs from "dayjs";
import { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux"

import {
  addMessages,
  getBotCurrentState,
  toggleBotInterface,
  updateBotState,
  updateUserLoginData,
} from "../data/store/botSlice"
import { getThemeConfig, updateThemeState } from "../data/store/themeSlice"
import { IFRAME_EVENT_TYPE, LOCAL_STORAGE } from "../data/configs/constants"
import useDelayUnmount from "../data/hooks/useDelayUnmount"
import {
  getUrlParams,
  isAppleDevice,
  throttled,
  getBrowserDetails, sendUpdateToParent, isEmptyObject, setDataInLocalStorage, getDataFromLocalStorage,
  getContrastYIQ,
  adjustColor,
} from "../data/configs/utils"

import ErrorBoundary from "./components/ErrorBoundary"
import ShowMessage from "./components/ShowMessage"
import Chatbot from "./core/Chatbot"
import Notifications from "./core/Notifications"
import 'remixicon/fonts/remixicon.css'
import * as _ from 'lodash'
import ChatTrigger from "./core/ChatTrigger"
import OriButton from "./components/Custom/OriButton";

var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const PARAMS = getUrlParams()

const App = () => {
  const dispatch = useDispatch()
  const scrollTimerRef = useRef(null)
  const [parentPathName, setParentPathName] = useState(
    PARAMS.initialParentPathName || ""
  )

  const themeMode = useSelector((state) => state.themeDetails.themeMode)
  const primaryColor = useSelector((state) => state.themeDetails.primaryColor)
  const baseColor = useSelector((state) => state.themeDetails.baseColor)
  const systemDefault = useSelector((state) => state.themeDetails.systemDefault)
  const botPosition = useSelector((state) => state.themeDetails.botPosition)
  const secondaryColor = useSelector(
    (state) => state.themeDetails.secondaryColor
  )
  const fontSize = useSelector((state) => state.themeDetails.fontSize)
  const direction = useSelector((state) => state.themeDetails.direction)
  const triggerUrl = useSelector((state) => state.themeDetails.triggerUrl)
  const triggerShape = useSelector((state) => state.themeDetails.triggerShape)
  const language = useSelector((state) => state.themeDetails.language)

  const loadError = useSelector((state) => state.themeDetails.loadError)
  const loading = useSelector((state) => state.themeDetails.loading)
  const fullScreenBot = useSelector((state) => state.themeDetails.fullScreenBot)
  const triggerSize = useSelector((state) => state.themeDetails.triggerSize)
  const isBotOpen = useSelector((state) => state.botDetails.isBotOpen)
  const tenantId = useSelector((state) => state.botDetails.tenantId)
  const psid = useSelector((state) => state.botDetails.psid)
  const isTestBot = useSelector((state) => state.botDetails.isTestBot)
  const loginAttributes = useSelector((state) => state.themeDetails.userLoginAttributes);

  const delayUnmountBot = useDelayUnmount(isBotOpen, 500)

  // let theme = useMemo(
  //   () =>
  //     createTheme(
  //       themeConfig[themeMode]({
  //         primaryColor,
  //         secondaryColor,
  //         fontSize,
  //         direction,
  //       })
  //     ),
  //   [themeMode, primaryColor, secondaryColor, fontSize, direction]
  // )

  // theme = responsiveFontSizes(theme)

  const init = useCallback(() => {
    if (!tenantId || tenantId === "null") {
      return;
    }
    const payload = { tenantId, data: { isTestBot } }
    dispatch(getThemeConfig(payload))
      .unwrap()
      .then((res) => {
        let _quickReplies = [];
        if (res.data?.quickReplies?.length > 0) {
          _quickReplies = [...res.data.quickReplies]
        }
        sendUpdateToParent(IFRAME_EVENT_TYPE.MOVE_TO_LEFT, { botPosition: res.data.botPosition })
        if(res.data?.botAlignment) {

          const x = (res.data?.botAlignment?.x || 10) + "px"
          const y = (res.data?.botAlignment?.y || 10) + "px"
          const iframeStyleToSend = {
            bottom: x,
            ...(res.data?.botPosition === "left" ? {left: y} : {right: y})
          }
          sendUpdateToParent(IFRAME_EVENT_TYPE.MODIFY_IFRAME_STYLES, { iframeStyles: iframeStyleToSend })
        }
        if (res.data.routeBasedNotifications)
          dispatch(
            updateBotState({
              routeBasedNotifications: res.data.routeBasedNotifications,
            })
          )
        if (res.data.welcomeMessage?.length > 0) {
          _.get(res, 'data.welcomeMessage', []).forEach((item, index) => {
            if (_.get(item, 'quickReplies.length', 0) > 0) {
              _quickReplies = [..._quickReplies, ...item.quickReplies]
            }
          })
          dispatch(
            addMessages({
              messages: res.data.welcomeMessage,
              skipUnread: true,
            })
          )
          dispatch(
            updateBotState({
              welcomeMessages: res.data.welcomeMessage,
            })
          )
        }
        dispatch(updateBotState({ quickReplies: _quickReplies }));
      })
    dispatch(
      getBotCurrentState({
        psid,
        tenantId,
      })
    )
  }, [dispatch, isTestBot, psid, tenantId])

  const loadLocaleOnDemad = async (locale) => {
    try {
        await import(`dayjs/locale/${locale}.js`)
        dayjs.locale(locale)
    } catch (err) {
        console.error("Error setting locale for dayjs:", err)
        // set fallback locale to english
        dayjs.locale('en')
    }
  }

  const setUserDetails = useCallback((data) => {
    let payload = {}
    for (let key in data) {
      if (key in loginAttributes) {
        if (typeof (data[key]) !== 'string') {
          sendUpdateToParent(IFRAME_EVENT_TYPE.GET_USER_LOGIN_ATTRIBUTES_ERROR, { error: 'Please Provide all the details in string format' });
          return;
        }
        if (loginAttributes[key].label === 'Email' && data[key].length > 50) {
          sendUpdateToParent(IFRAME_EVENT_TYPE.GET_USER_LOGIN_ATTRIBUTES_ERROR, { error: 'Email should be less than equal to 50 characters' });
          return;
        }
        if (loginAttributes[key].label === 'Name' && data[key].length > 50) {
          sendUpdateToParent(IFRAME_EVENT_TYPE.GET_USER_LOGIN_ATTRIBUTES_ERROR, { error: 'Name should be less than equal to 50 characters' });
          return;
        }
        if (loginAttributes[key].label === 'Phone No.') {
          if (data[key].length > 24) {
            sendUpdateToParent(IFRAME_EVENT_TYPE.GET_USER_LOGIN_ATTRIBUTES_ERROR, { error: 'Phone Number should be less than equal to 24 digits' });
            return;
          }
        }
        if (loginAttributes[key].label === 'User ID') {
          if (data[key].length > 50) {
            sendUpdateToParent(IFRAME_EVENT_TYPE.GET_USER_LOGIN_ATTRIBUTES_ERROR, { error: 'User Id should be less than equal to 24 digits' });
            return;
          }
        }
        payload = {
          ...payload,
          [key]: data[key]
        }
      }
    }
    dispatch(updateUserLoginData(payload));
    sendUpdateToParent(IFRAME_EVENT_TYPE.GET_USER_LOGIN_ATTRIBUTES_SUCCESS, { success: 'User Details Updated Successfully' });
  }, [dispatch, loginAttributes])

  useEffect(() => {
    const localData = getDataFromLocalStorage(LOCAL_STORAGE.LOGIN_ATTRIBUTES);
    if (localData && !isEmptyObject(localData)) {
      setUserDetails(localData)
    }
  }, [setUserDetails])

  useEffect(() => {
    dispatch(
      updateThemeState({
        deviceData: {
          os: navigator.platform,
          userAgent: navigator.userAgent,
          browser: getBrowserDetails(),
        },
      })
    )
  }, [dispatch])

  useEffect(() => {
      //on language change load locale on demand
      loadLocaleOnDemad(language)
  },[language])

  useEffect(() => {
    init()
    if (PARAMS.defaultOpen === "true" || fullScreenBot) {
      if (fullScreenBot)
        dispatch(toggleBotInterface({ reason: "fullScreenBot" }))
      else dispatch(toggleBotInterface())
    }
  }, [dispatch, fullScreenBot, init])

  useEffect(() => {
    const handleUpdateFromParent = (e) => {
      if (e.origin !== PARAMS.parentOrigin) return
      const { type, payload } = e.data
      if (type === IFRAME_EVENT_TYPE.ROUTE_PATH_CHANGED && payload.pathname) {
        setParentPathName(payload.pathname)
      }
      if (type === IFRAME_EVENT_TYPE.GET_USER_LOGIN_ATTRIBUTES) {
        // storing user details in local storage to handle refresh
        setDataInLocalStorage(LOCAL_STORAGE.LOGIN_ATTRIBUTES, payload);
        setUserDetails(payload)
      }
      if (type === IFRAME_EVENT_TYPE.MAXIMIZE_CHATBOT) {
        if (!isBotOpen) handleTriggerClick()
      }
      if (type === IFRAME_EVENT_TYPE.MINIMIZE_CHATBOT) {
        if (isBotOpen) handleTriggerClick()
      }
    }

    window.addEventListener("message", handleUpdateFromParent)
    return () => {
      window.removeEventListener("message", handleUpdateFromParent)
    }
  }, [setUserDetails, isBotOpen])

  useEffect(() => {
    const isApple = isAppleDevice()
    const handleScrollbarView = throttled((event) => {
      const botContainer = document.getElementById("saas-chatbot")
      if (botContainer && botContainer.contains(event.target)) {
        event.target.style.setProperty("--scroll-display", "visible")
        event.target.classList.add("prevent-inheritance")
        if (scrollTimerRef.current) {
          clearTimeout(scrollTimerRef.current)
        }
        scrollTimerRef.current = setTimeout(() => {
          event.target.style.setProperty("--scroll-display", "none")
        }, 800)
      }
    }, 800)
    if (!isApple) {
      const element = document.getElementById("saas-chatbot")
      if (element) {
        element.classList.add("scroll-visibility")
        window.addEventListener("scroll", handleScrollbarView, true)
      }
    }
    return () => {
      if (!isApple) {
        window.removeEventListener("scroll", handleScrollbarView)
        if (scrollTimerRef.current) clearTimeout(scrollTimerRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (direction === "rtl") document.dir = "rtl"
  }, [direction])

  // setting up theme variables
  useEffect(() => {
    const root = document.documentElement;
    const primaryContrast = getContrastYIQ(primaryColor);
    const secondaryContrast = getContrastYIQ(secondaryColor);
    let backgroundColor = "";

    // ------------- SETTING BASE BACKGROUND COLOR ---------------------------------
    if(baseColor === "#ffffff") backgroundColor = adjustColor(baseColor, -2)
    else if(baseColor === "#000000") backgroundColor = adjustColor(baseColor,10)

    const backgroundColorContrast = getContrastYIQ(backgroundColor);
    root.style.setProperty('--background-color', backgroundColor);
    root.style.setProperty('--background-color-contrast', backgroundColorContrast);

    // ------- SETTING PRIMARY AND SECONDARY COLORS --------------------------------
    root.style.setProperty('--primary-color', primaryColor);
    root.style.setProperty('--primary-color-contrast', primaryContrast);
    root.style.setProperty('--secondary-color', secondaryColor);
    root.style.setProperty('--secondary-color-contrast', secondaryContrast);
    
    // -------- SETTING FONT SIZE BASE AND DIRECTION -------------------------------
    root.style.setProperty('--font-size-base', fontSize);
    root.style.setProperty('--direction', direction);

    // ---------- SETTING CHAT BUBBLE COLORS ----------------------------------
    root.style.setProperty('--user-chat-bubble-color', primaryColor);
    root.style.setProperty('--user-chat-bubble-color-contrast', getContrastYIQ(primaryColor));

    if(baseColor === "#ffffff"){
      root.style.setProperty('--bot-chat-bubble-color', adjustColor(backgroundColor, -2));
      root.style.setProperty('--bot-chat-bubble-color-contrast', getContrastYIQ(adjustColor(backgroundColor, -2)));
      root.style.setProperty('--border-color', adjustColor(backgroundColor, -10));
      root.style.setProperty('--link-color',adjustColor(primaryColor, -10))
      root.style.setProperty('--link-color-visited',adjustColor(primaryColor, 10))
      root.style.setProperty('--link-color-active',"#FF0000")
    } else if(baseColor === "#000000"){
      root.style.setProperty('--bot-chat-bubble-color', adjustColor(backgroundColor, 8));
      root.style.setProperty('--bot-chat-bubble-color-contrast', getContrastYIQ(adjustColor(backgroundColor, 8)));
      root.style.setProperty('--border-color', adjustColor(backgroundColor, 10));
      root.style.setProperty('--link-color',adjustColor(primaryColor, 20))
      root.style.setProperty('--link-color-visited',adjustColor(primaryColor, 25))
      root.style.setProperty('--link-color-active',"#BF616A")
    }

  }, [primaryColor, secondaryColor, fontSize, direction, baseColor])

  useEffect(() => {
    const darkThemeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
    const matchMediaDarkModeListener = (matches) => {
      if(matches){
        dispatch(updateThemeState({baseColor: "#000000"}))
      } else {
        dispatch(updateThemeState({baseColor: "#ffffff"}))
      }
    }

    if(systemDefault){
      matchMediaDarkModeListener(darkThemeMediaQuery.matches)
      darkThemeMediaQuery.addEventListener('change', (e) => matchMediaDarkModeListener(e.matches))
    }else {
      darkThemeMediaQuery.removeEventListener('change', (e) => matchMediaDarkModeListener(e.matches))
    }

    return () => {
      darkThemeMediaQuery.removeEventListener('change', matchMediaDarkModeListener)
    }
  },[systemDefault, dispatch])

  const handleTriggerClick = () => {
    dispatch(toggleBotInterface())
  }

  if (!fullScreenBot && (loading || loadError)) return null
  return (
    <div className="text-base">
      {/* <ThemeProvider theme={theme}> */}
      {/* <CssBaseline /> */}
      {!fullScreenBot && (
        <ChatTrigger
          triggerSize={triggerSize}
          triggerShape={triggerShape}
          triggerUrl={triggerUrl}
          botPosition={botPosition}
          isBotOpen={isBotOpen}
          handleTriggerClick={handleTriggerClick}
        />
      )}
      {fullScreenBot && !loading && loadError ? (
        <ShowMessage
          title="Something went wrong"
          message="We are unable to load the chatbot configuration. You can click on the retry button to load it again"
          containerProps={{
            height: "100vh",
          }}
        >
          <OriButton
            variant="filled"
            onClick={init}
          >
            Retry
          </OriButton>
        </ShowMessage>
      ) : (
        <>
          {delayUnmountBot && (
            <Chatbot
              className={
                isBotOpen
                  ? `Animated ${fullScreenBot ? "FadeIn" : "ZoomIn"
                  }`
                  : "Animated ZoomOut"
              }
            />
          )}
          <ErrorBoundary>
            {!isBotOpen && <Notifications parentPathName={parentPathName} />}
          </ErrorBoundary>
        </>
      )}
      {/* </ThemeProvider> */}
    </div>
  )
}

export default App
